.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header > div {
  width: 49.5%;
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px 4px 0px 0px;
  color: white;
}

.header > div:first-child {
  background: #c317ff;
}

.header > div:last-child {
  background: #004892;
}

.header h5 {
  margin-top: 12px !important;
  margin-bottom: 9px;
}
.header > div > div {
  display: flex;
  align-items: center;
}
.header > div > div > div {
  width: 50px;
  height: 30px;
  background: #f1f4f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
