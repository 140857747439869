h1 {
  text-align: center;
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 930px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerGrid {
  /* padding: 20px; */
  width: 100%;
  background: #f1f4f6;
  position: relative;
}

.playButton {
  width: 200px;
  height: 200px;
  background: linear-gradient(133.29deg, #01fec2 14.49%, #0080fe 85.93%);
  box-shadow: inset 0px 4px 20px rgba(115, 115, 115, 0.25);
  border-radius: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  cursor: pointer;
  letter-spacing: 0.08em;
  color: #ffffff;
}

.memory_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.memory_grid > div {
  width: 170px;
  height: 170px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgba(0, 91, 185, 1);
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}

.memory_grid > .closed h1 {
  opacity: 0;
  color: #fff;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
}

.memory_grid > .closed:after {
  content: "";
  background: #005bb9;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 170px;
  height: 170px;
  transform: rotate3d(1, 1, 1, 0deg);
  transition: 20000ms ease transform;
}

.memory_grid > .disabled h1 {
  color: #fff;
  z-index: 8;
}

.memory_grid > .disabled:after {
  content: "";
  pointer-events: none;
  background: #d32b2e;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 170px;
  height: 170px;
  transform: rotate3d(1, 1, 1, 0deg);
  transition: 20000ms ease transform;
}
.memory_grid > .open h1 {
  color: #fff;
  z-index: 8;
}
.memory_grid > .open:after {
  content: "";
  pointer-events: none;
  background: #3a8201;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 170px;
  height: 170px;
  transform: rotate3d(1, 1, 1, 0deg);
  transition: 20000ms ease transform;
}

.header {
  width: 100%;
  background: #5d8dbe;
  border-radius: 4px 4px 0px 0px;
  padding: 21px 0;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score {
  display: flex;
  align-items: center;
  margin-left:20px;
}
.score h3 {
  font-family: "Montserat-Bold";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.whiteBox {
  padding: 10px 14px;
  min-height: 41px;
  min-width: 50px;
  background: #f1f4f6;
  border-radius: 4px;
  text-align: center;
}
.rightStyles {
  display: flex;
  align-items: center;
  margin-right:20px;
}
.score .whiteBox {
  font-family: "Montserat-Bold";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.moves {
  color: #646464;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.moves h3,
.moves .num {
  margin: 0;
  font-family: "Montserat-Bold";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #646464;
}
.timer {
  font-family: "Montserat-Bold";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.footer {
  padding: 13px 0;
  background: #cdd7e1;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.footerListParent {
  width: 90%;
  padding: 0;
 
}

.footerList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.footerListTitle {
  font-family: "Montserat-Bold";
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-right: 20px;
}
.footerListCards {
  display: flex;
  grid-gap: 20px;
  align-items: center;
}
.footerListCards .card {
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  padding: 7px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 91, 185, 1);
}
.footerListCards .selected {
  pointer-events: none;
  background: #ff5161;
  border-radius: 4px;
  border-radius: 4px;
  padding: 7px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}
